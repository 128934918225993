// https://github.com/twbs/bootstrap-rubygem/blob/master/assets/stylesheets/bootstrap/_variables.scss
// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

@use "sass:map";
@use "_colors";

$theme-colors: (
  "primary": colors.$primary,
  "secondary": colors.$secondary,
  "success": colors.$success,
  "info": colors.$info,
  "warning": colors.$warning,
  "danger": colors.$danger,
  "white": colors.$white,
  "light": colors.$light,
  "mid": colors.$mid,
  "dark": colors.$dark,
) !default;

$custom-colors: (
  "brand": colors.$brand,
  // "brand-darker": $brand-darker,
  "lighter": colors.$lighter,
  "lightish": colors.$lightish,
  // "mid": $mid,
  // "darker": $darker,
);

// .btn-brand.s60x60 {
//   &.active {
//     box-shadow: var(--bs-btn-focus-box-shadow);
//   }
// }

// Merge the maps
// $custom-colors: map-merge($custom-colors, $happiness-colors);
$theme-colors: map.merge($theme-colors, $custom-colors);

$body-color: colors.$gray-800 !default;

$font-family-sans-serif: "Open Sans", sans-serif !default;
$font-family-serif: "Times New Roman", serif !default;
$headings-font-family: "Oswald", sans-serif !default;
$btn-font-family: "Oswald", sans-serif !default;
$lead-font-weight: 400 !default;
$display-font-weight: 400 !default;

$navbar-dark-color: rgba(colors.$white, 0.9) !default;
$navbar-dark-hover-color: colors.$white !default;
$navbar-dark-active-color: colors.$dark !default;
$navbar-dark-toggler-border-color: rgba(colors.$white, 0.5) !default;

$btn-focus-box-shadow: 0 0 0 0.25rem rgba(colors.$primary, 0.25) !default;

$input-focus-border-color: colors.$primary;
$input-focus-box-shadow: 0 0 0 0.25rem rgba(colors.$primary, 0.25) !default;

$utilities: (
  "opacity-hover": (
    property: opacity,
    responsive: false,
    class: opacity,
    state: hover,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      100: 1,
    ),
  ),
);

$enable-negative-margins: true;

// $link-color: $brand !default;
$component-active-bg: colors.$brand !default;
$nav-link-color: colors.$brand !default;
$nav-link-hover-color: colors.$brand-darker !default;

$accordion-button-color: colors.$gray-500 !default;
$accordion-button-bg: colors.$gray-100 !default;
$accordion-button-active-bg: colors.$gray-400 !default;
$accordion-button-active-color: colors.$gray-100 !default;

.font-normal {
  font-family: $font-family-sans-serif !important;
}
.font-display {
  font-family: $headings-font-family !important;
}
.font-serif {
  font-family: $font-family-serif !important;
}

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$h7-font-size: $font-size-base * 0.875 !default;
$h8-font-size: $font-size-base * 0.75 !default;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
  8: $h8-font-size,
) !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3 !default;

$pagination-padding-x: 0.5rem !default;

$table-hover-bg: colors.$gray-100 !default;

$dropdown-dark-color: colors.$gray-200 !default;
$dropdown-dark-bg: colors.$gray-700 !default;

.table-striped-columns > :not(caption) > tr > :nth-child(2n),
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: rgb(237, 233, 222, 0.2) !important;
  --bs-table-striped-bg: rgb(237, 233, 222, 0.2) !important;
}

$min-contrast-ratio: 2;

$card-cap-bg: colors.$gray-200;

// Temp fix for this issue: https://github.com/twbs/bootstrap/issues/36820
// .pagination .page-item {
//   .page-link {
//     border-radius: 0px;
//   }
//   &:first-child > .page-link {
//     border-top-left-radius: var(--bs-pagination-border-radius);
//     border-bottom-left-radius: var(--bs-pagination-border-radius);
//   }
//   &:last-child .page-link {
//     border-top-right-radius: var(--bs-pagination-border-radius);
//     border-bottom-right-radius: var(--bs-pagination-border-radius);
//   }
// }

// pre.code > code {
//   color: var(--bs-code-color) !important;
// }
